<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="subjects"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách môn học
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="addNewItem"
            >Thêm mới
            </v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{formatDateTime(item.created_at)}}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.new_img="{ item }">
          <img :src="item.new_img" style="width: 50px; height: 50px"/>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
      <!-- DIALOG DELETE  -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
          >Are you sure you want to delete this item?
          </v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancel
            </v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK
            </v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {GET_LIST_SUBJECTS, DELETE_SUBJECT, SET_FILTER_SUBJECT} from "@/store/subject.module";
import {ERROR} from "@/store/alert.module";

export default {
  data: () => ({
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Tên môn học", value: "name"},
      {text: "Ảnh", value: "new_img"},
      {text: "Thứ tự", value: "order"},
      {text: "Kích hoạt", value: "status"},
      {text: "Ngày tạo", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    editedIndex: -1,
    editedId: -1,
    dialogDelete: false,
  }),
  watch: {
    subjects: {
      deep: true,
      immediate: true,
      handler: function () {
        this.subjects.forEach((subject) => {
          if (subject.img.includes(process.env.VUE_APP_BASE_URL)) {
            subject.new_img = subject.img
          } else {
            subject.new_img = process.env.VUE_APP_BASE_URL + "/" + subject.img;
          }
        });
      },
    },
  },
  computed: {
    ...mapState({
      subjects: (state) => state.subject.subjects,
    }),
  },
  mounted() {
    this.getSubjects();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Môn học", route: "Môn học"},
    ]);
  },
  methods: {
    getSubjects() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_LIST_SUBJECTS)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    addNewItem() {
      this.$router.push({name: "subject-detail"});
    },

    editItem(item) {
      this.$router.push({name: "subject-detail", query: {slug: item.slug}});
    },

    deleteItem(item) {
      this.editedIndex = this.subjects.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_SUBJECT, this.editedId).then((data) => {
        if (data.status) {
          this.$store.commit(SET_FILTER_SUBJECT, this.editedId);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    formatDateTime(date) {
      if (!date) return null
      const newDate = new Date(date)
      const [year, month, day, hour, minute, millisecond] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate(),
        newDate.getHours(), newDate.getMinutes(), newDate.getMilliseconds()]
      const hour_format = hour > 9 ? hour : `0${hour}`
      const minute_format = minute > 9 ? minute : `0${minute}`
      const millisecond_format = millisecond > 9 ? millisecond : `0${millisecond}`
      return `${hour_format}:${minute_format}:${millisecond_format} ${day}/${month}/${year}`
    },
  },
};
</script>
<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
